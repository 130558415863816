.VideoDocuments__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.VideoDocuments__videoContainer {
    max-width: 100%;
    width: 400px;
    height: 300px;
    position: relative;
}

.VideoDocuments__videoContainer-editing {
    max-width: 100%;
    width: 150px;
    height: 100px;
    position: relative;
}

.VideoDocuments__hoverIcon {
    display: none;
    visibility: hidden;
    z-index: 1;
    position: absolute;
    top: 4px;
    right: 4px;
}

.VideoDocuments__videoContainer:hover .VideoDocuments__hoverIcon {
    display: inline-block;
    visibility: visible;
    z-index: 1;
    position: absolute;
    top: 4px;
    right: 4px;
}

@media screen and (max-width: 768px) {
    .VideoDocuments__container {
        flex-direction: column;
    }

    .VideoDocuments__videoContainer {
        width: 100% !important;
    }
}
