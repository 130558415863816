.divider {
    height: 16px;
    background-color: #eee;
    width: 100%;
}

@keyframes scale-up {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@-webkit-keyframes scale-up {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}
