@import "node_modules/bulma/bulma.sass";

.EditableSelector__editableInput {
    @extend .select;
    & > select {
        border-width: 1px;
        padding-right: 0; }
    & > select:not([multiple]) {
        padding-right: calc(0.75em - 1px); }
    &::after {
        border-color: grey; }
    &:not(.is-multiple):not(.is-loading)::after {
        opacity: 0; }
    &:hover:not(.is-multiple):not(.is-loading)::after {
        opacity: 1; }
    &:hover > select {
        background-color: white;
        border-width: 1px;
        border-color: #dbdbdb;
        border-radius: 4px;
        color: #363636;
        padding-right: 2.5em; } }

