//@import "../node_modules/bulma/bulma.sass"
//@import "../node_modules/bulma-extensions/bulma-slider/dist/css/bulma-slider.sass"
//@import "../node_modules/bulma-extensions/bulma-switch/dist/css/bulma-switch.sass"
@import "node_modules/bulma/bulma.sass";

// Import BulmaTagsInput main Sass File
@import '@creativebulma/bulma-tagsinput/src/sass/index';

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif; }


// Globals

.has-inner-shadow {
  -moz-box-shadow:    inset 0 0 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  box-shadow:         inset 0 0 2px rgba(0, 0, 0, 0.3); }

.extra-column-gap {
  padding: 0.75rem; }

.extra-column-gap-br {
  padding-bottom: 0.75rem;
  padding-right: 0.75rem; }

// Custom class to disable elements
.is-disabled {
  pointer-events: none;
  opacity: .65; }

.is-subtle {
  background: rgba(255, 255, 255, 0.2) !important; }

.is-subtle:hover {
  background: rgba(255, 255, 255, 0.5) !important; }

.is-hidden-not-printing {
  display: none; }

.with-animated-ellipsis:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4,end) 900ms infinite;
  animation: ellipsis steps(4,end) 900ms infinite;
  content: "\2026";
  width: 0px; }

@keyframes ellipsis {
  to {
    width: 1.25em; } }

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em; } }

@media print {

  .is-hidden-print {
    display: none !important; }

  .is-hidden-not-printing {
    display: inherit !important; }

  table {
    page-break-after: auto; }

  tr {
    page-break-inside: avoid;
    page-break-after: auto; }

  td {
    page-break-inside: avoid;
    page-break-after: auto; }

  thead {
    display: table-header-group; }

  tfoot {
    display: table-footer-group; } }

.zIndexMedium {
  z-index: 40; }

.zIndexHigh {
  z-index: 10000; }

// custom colors

$light-primary: rbga($warning, 0.5);
$light-warning: rbga($warning, 0.5);
$light-danger: rbga($warning, 0.5);
$light-info: rbga($info, 0.5);
$light-link: rbga($link, 0.5);

// custom backgrounds

.has-light-primary-background {
  background-color: findLightColor($primary) !important; }

.has-light-warning-background {
  background-color: findLightColor($warning) !important; }

.has-light-danger-background {
  background-color: findLightColor($danger) !important; }

.has-light-info-background {
  background-color: findLightColor($info) !important; }

.has-light-link-background {
  background-color: findLightColor($link) !important; }

.is-clickable {
  pointer-events: auto !important;
  cursor: pointer; }

.is-not-clickable {
  pointer-events: none; }

.pane-scroll {
  height: calc(100vh - 52px);
  overflow: hidden;
  overflow-y: scroll; }

.highlight-with-color {
  animation: fade_to_white 1s ease-in-out; }

.max-form-width {
  max-width: 768px !important; }

.margin-auto {
  margin: auto; }

.bottom-shadow {
  box-shadow: 0px -5px 11px 1px rgb(0, 0, 0 / 75%) !important; }

.top-shadow {
  box-shadow: 0px 5px 11px 1px rgb(0, 0, 0 / 75%) !important; }

.is-background-color-white {
  background-color: white !important; }

.is-top-border-1px {
  border-top: 1px rgb(219, 219, 219) solid; }

.is-overflow-hidden {
  overflow: hidden; }

.is-overflow-x-scroll {
  overflow-x: scroll !important;
  -webkit-overflow-scrolling: auto !important; }

.is-full-max-w {
  max-width: 100% !important; }

.is-full-w {
  width: 100% !important; }

.is-borderless {
  border-radius: unset !important; }

.is-text-nowrap {
  white-space: nowrap; }

// see: https://stackoverflow.com/questions/24193272/overflow-xhidden-on-mobile-device-not-working
.html-overflow-hidden {
  overflow: hidden !important;
  //position: relative !important
 }  //width: 100% !important

@for $i from 1 through 10 {
  .is-z-#{$i * 10} {
    z-index: $i * 10 !important; } }

@keyframes fade_to_white {
  0% {
    background-color: findLightColor($warning); }

  100% {
    background-color: white; } }

.button.is-inverted.is-loading {
    &:hover,
    &:focus,
    &:active,
    &:visited {
      &::after {
        border-color: transparent transparent $grey $grey !important; } } }

.button.is-outlined.is-loading {
  &:hover,
  &:focus,
  &:active,
  &:visited {
    &::after {
      border-color: transparent transparent $grey $grey !important; } } }

.space-4-y > *:not(:first-child) {
  margin-top: 1rem; }
