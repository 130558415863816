.Modal__card {
    border-radius: 6px;
    animation-duration: 300ms;
    animation-name: open-up-modal;
}

.Modal__background {
    animation-duration: 300ms;
    animation-name: opacity-transition;
    opacity: 0.4;
}

.Modal__close {
    transform: scale(0.95) translateY(4rem);
    animation-duration: 300ms;
    animation-name: close-modal;
}
.Modal__backgroundClose {
    opacity: 0;
    animation-duration: 300ms;
    animation-name: close-background;
}

@keyframes opacity-transition {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.4;
    }
}

@-webkit-keyframes opacity-transition {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.4;
    }
}

@keyframes open-up-modal {
    0% {
        opacity: 0;
        transform: scale(0.95) translateY(4rem);
    }

    100% {
        opacity: 1;
        transform: scale(1) translateY(0);
    }
}

@-webkit-keyframes open-up-modal {
    0% {
        opacity: 0;
        transform: scale(0.95) translateY(4rem);
    }

    100% {
        opacity: 1;
        transform: scale(1) translateY(0);
    }
}

@keyframes close-modal {
    0% {
        opacity: 1;
        transform: scale(1) translateY(0);
    }

    100% {
        opacity: 0;
        transform: scale(0.95) translateY(4rem);
    }
}

@-webkit-keyframes close-modal {
    0% {
        opacity: 1;
        transform: scale(1) translateY(0);
    }

    100% {
        opacity: 0;
        transform: scale(0.95) translateY(4rem);
    }
}

@keyframes close-background {
    from {
        opacity: 0.4;
    }
    to {
        opacity: 0;
    }
}

@-webkit-keyframes close-background {
    from {
        opacity: 0.4;
    }
    to {
        opacity: 0;
    }
}
