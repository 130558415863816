/* add css module styles here (optional) */

._3ybTi {
  margin: 2em;
  padding: 0.5em;
  border: 2px solid #000;
  font-size: 2em;
  text-align: center;
}

/* Scale canvas with resize attribute to full size */
canvas[data-paper-resize] {
  width: 100%;
  height: 100%;
}

._-WYis {
  display: flex;
  flex-direction: column;
}

._1iLpS {
  display: flex;
  flex-direction: row;
}

._3hCvN {
  flex-wrap: wrap;
}

._2XKrJ {
  font-size: 10px;
  justify-content: flex-end;
  padding: 0 0.5rem;
}

#_35VNW {
  display: none;
  z-index: 100;
}

/*#notepad{*/
/*  position:absolute;*/
/*  left:25%;*/
/*  top:10%;*/
/*  width:300px;*/
/*  height:300px;*/
/*  background-color:red;*/
/*}*/

menu {
  position:absolute;
  display: block;
  left: 0;
  top: 0;
  /*height:20px;*/
  /*width:20px;*/
  height: 0;
  width: 0;
  padding: 0;
  margin:0;
  /*border: 1px solid;*/
  background-color:white;
  font-weight:normal;
  white-space:nowrap;
}
menu:hover {
  background-color: #eef;
  font-weight: bold;
}
/*menu:hover > menu {*/
/*  display: block;*/
/*}*/
menu > menu{
  display: block;
  position: relative;
  top: -20px;
  left: 100%;
  height: 20px;
  width: 200px;
  padding: 4px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: lightgrey;
}
menu[title]:before {
  content:attr(title);
}
/*menu:not([title]):before {*/
/*  !*content:"\2630";*!*/
/*}*/
